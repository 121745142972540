import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Chatbot",
  "author": "admin",
  "date": "2019-01-01T00:00:00.000Z",
  "image": "img/livechat.svg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Chatbot`}</h1>
    <h2>{`Chatbot`}</h2>
    <h3>{`Chatbot`}</h3>
    <p>{`Create a chatbot for your Website`}</p>
    <p>{`Create complex chat response scenarios with Crisp chatbot without human effort. Looking to create a chatbot for your website ? This integration provides an easy to use IDE that makes chatbot building easy. You don't need any coding skills.`}</p>
    <p>{`Benefits of creating a chatbot for your website:
Automate first-level support`}</p>
    <p>{`Build advanced scenarios based on user answers`}</p>
    <p>{`Create Human-like experiences with GIFs, typing indicators, file sharing`}</p>
    <p>{`Send predefined choices with button pickers`}</p>
    <p>{`Automate data enrichment through users interactions`}</p>
    <p>{`Combine chatbot with marketing and sales automation to put your customer journey on autopilot`}</p>
    <p>{`Assign automatically segment to users through conversational experience`}</p>
    <p>{`Bet on conversational experience to delight your customers`}</p>
    <p>{`Delete all your website's forms and replace them by chatbots`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      